import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadFile } from '../../../../../utils/fileDownloader'
import useJackpotContribution from '../../hooks/useJackpotDistribution'
import { faRedo, faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons'
import { Row, Col, Tabs, Tab, Button, Card } from '@themesberg/react-bootstrap'
import { InlineLoader, SpinnerLoader } from '../../../../../components/Preloader'
import JackpotContributionTable from '../../../../../components/JackpotContributionTable'

export default ({ selectedPortal = '', selectedClient = '', accordionState, isHidden }) => {
  const {
    state,
    hover,
    sortBy,
    loading,
    setState,
    setHover,
    setSortBy,
    selectedTab,
    fetchDetails,
    setSelectedTab,
    getCsvDownloadUrl,
    jackpotContribution
  } = useJackpotContribution({ selectedPortal, selectedClient, accordionState })

  const TotalData = () => (
    <div className='total-cards d-flex gap-2'>
      <Card body className='w-100 text-center card-container'>
        <div>
          <p> Total Jackpot Contribution </p>
        </div>
        <div className='header-font-weight'>
          {typeof jackpotContribution?.[0]?.jackpotContribution === 'number' && !loading
            ? (
              <div className={`amount ${(jackpotContribution?.[0]?.jackpotContribution > 0) ? 'text-success' : 'text-danger'}`}>
                {`${jackpotContribution?.[0]?.jackpotContribution.toFixed(2)} EUR`}
              </div>
              )
            : (
              <small className='text-danger text-center'>
                No data found
              </small>
              )}
        </div>
      </Card>
      <Card body className='w-100 text-center card-container'>
        <div>
          <p> Total Jackpot Win </p>
        </div>
        <div className='header-font-weight'>
          {typeof jackpotContribution?.[0]?.jackpotWin === 'number' && !loading
            ? (
              <div className={`amount ${(jackpotContribution?.[0]?.jackpotWin > 0) ? 'text-success' : 'text-danger'}`}>
                {`${jackpotContribution?.[0]?.jackpotWin.toFixed(2)} EUR`}
              </div>
              )
            : (
              <small className='text-danger text-center'>
                No data found
              </small>
              )}
        </div>
      </Card>
      <Card body className='w-100 text-center card-container'>
        <div>
          <p> Total Jackpot Revenue </p>
        </div>
        <div className='header-font-weight'>
          {typeof jackpotContribution?.[0]?.jackpotRevenue === 'number' && !loading
            ? (
              <div className={`amount ${(jackpotContribution?.[0]?.jackpotRevenue > 0) ? 'text-success' : 'text-danger'}`}>
                {`${jackpotContribution?.[0]?.jackpotRevenue.toFixed(2)} EUR`}
              </div>
              )
            : (
              <small className='text-danger text-center'>
                No data found
              </small>
              )}
        </div>
      </Card>
    </div>
  )

  return (
    <>
      <Row className='border-bottom d-flex align-items-center justify-content-between mb-2 pb-2'>
        <Col>
          <button onClick={() => fetchDetails()} className='btn btn-sm btn-secondary d-flex align-items-center gap-1'>
            <span className='fs-7'>Refresh</span>
            {loading ? <SpinnerLoader /> : <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />}
          </button>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <input value={state} type='month' className='form-control form-control-sm me-2' onChange={(e) => setState(e.target.value)} />
            <Button
              variant='success'
              size='sm'
              style={{ width: '150px' }}
              disabled={jackpotContribution?.length < 1}
              hidden={isHidden?.({ module: { key: 'JackpotReport', value: 'DR' } }) || selectedTab === 'total'}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              Export&nbsp;<FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>

          </div>
        </Col>

      </Row>

      <Tabs
        activeKey={selectedTab}
        onSelect={(tab) => setSelectedTab(tab)}
        className='nav-light dashboard'
      >
        {['total', 'game', 'provider'].map(tabName => (
          <Tab eventKey={tabName} title={tabName?.toUpperCase()} key={tabName}>
            {loading
              ? <div className='mt-4'><InlineLoader /></div>
              : (tabName === 'total'
                  ? <TotalData />
                  : (
                    <div className='mt-2'>
                      <Row className='mt-3 d-flex flex-row-reverse text-right'>
                        <JackpotContributionTable
                          sortBy={sortBy}
                          setSortBy={setSortBy}
                          hover={hover}
                          setHover={setHover}
                          tableData={jackpotContribution || []}
                        />
                      </Row>
                    </div>
                    ))}
          </Tab>))}
      </Tabs>
    </>
  )
}
