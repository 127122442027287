import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { tagSchema } from './schema'
import BonusDetailModal from './BonusDetailModal'
import { toast } from '../../../../components/Toast'
import CreatableSelect from 'react-select/creatable'
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table
} from '@themesberg/react-bootstrap'
import { useDispatch } from 'react-redux'
import { getBonusStart } from '../../../../store/redux-slices/bonus'
import { getAllBonusStart } from '../../../../store/redux-slices/admins'
import { useParams } from 'react-router-dom'
import { safeStringify } from '../../../../utils/helpers'

export const IsInternalModal = ({
  userData,
  isInternalModal,
  setIsInternalModal,
  internalPlayerHandler
}) => {
  return (
    <Modal show={isInternalModal}>
      <Modal.Header>
        <h4>
          Mark '{userData?.firstName} {userData?.lastName}' as Internal
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>
          Do you really want to mark{' '}
          <strong>
            {userData?.firstName} {userData?.lastName}
          </strong>{' '}
          as Internal?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setIsInternalModal(false)
          }}
        >
          No
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={internalPlayerHandler}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const AddAffiliateModal = ({
  userData,
  showAddAffiliate,
  setShowAddAffiliate,
  addAffiliateHandler
}) => {
  return (
    <Modal show={showAddAffiliate}>
      <Modal.Header className='d-flex justify-content-center'>
        <h4>
          Add '{userData?.firstName} {userData?.lastName}' as Affiliate
        </h4>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        <p>
          Do you really want to link{' '}
          <strong>
            {userData?.firstName} {userData?.lastName}
          </strong>{' '}
          as Affiliate using token <strong>'{userData?.trackingToken}'</strong>{' '}
          ?
        </p>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShowAddAffiliate(false)
          }}
        >
          No
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={addAffiliateHandler}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const RemoveAffiliateModal = ({
  userData,
  showRemoveAffiliate,
  setShowRemoveAffiliate,
  removeAffiliateHandler
}) => (
  <Modal show={showRemoveAffiliate}>
    <Modal.Header className='d-flex justify-content-center'>
      <h4>
        Remove '{userData?.firstName} {userData?.lastName}' from Affiliate
      </h4>
    </Modal.Header>
    <Modal.Body className='d-flex justify-content-center'>
      <p className='text-center'>
        Do you really want to remove{' '}
        <strong>
          {userData?.firstName} {userData?.lastName}
        </strong>{' '}
        from Affiliate(<strong>{userData?.affiliate?.username}</strong>) (
        <strong>{userData?.trackingToken}</strong>) ?
      </p>
    </Modal.Body>
    <Modal.Footer className='d-flex justify-content-between'>
      <Button
        className='m-2'
        variant='outline-warning'
        onClick={() => {
          setShowRemoveAffiliate((prev) => false)
        }}
      >
        No
      </Button>
      <Button
        className='m-2'
        variant='outline-success'
        style={{ width: '75px' }}
        onClick={() => {
          setShowRemoveAffiliate((prev) => false)
          removeAffiliateHandler()
        }}
      >
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
)

export const UserBonusIssueModal = ({
  userData,
  showModal,
  bonusList,
  bonusAmount,
  bonusDetail,
  setShowModal,
  selectedBonus,
  setBonusAmount,
  setSelectedBonus,
  isTenant = false,
  issueBonusHandler
}) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  useEffect(() => {
    showModal && dispatch(getAllBonusStart({ userId, bonusType: safeStringify(['match', 'balance', 'wagering', 'winBoost', 'zero_deposit']), isTenant }))
  }, [showModal])

  useEffect(() => {
    if (!selectedBonus && bonusList?.length > 0) {
      showModal && setSelectedBonus(bonusList?.[0])
    }
    if (selectedBonus) {
      showModal && dispatch(getBonusStart({ userBonusId: '', bonusId: selectedBonus?.bonusId, isTenant }))
    }
  }, [bonusList, selectedBonus?.bonusId])

  return (
    <Modal size='xl' show={showModal}>
      <Modal.Header className='justify-content-center'>
        <h4>
          Give Bonus to '{userData?.firstName} {userData?.lastName}'
        </h4>
      </Modal.Header>
      <Modal.Body>
        {bonusList?.length > 0 && (
          <Row>
            <Col style={{ maxWidth: '35%' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                AVAILABLE BONUSES
              </p>
              <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                <Table>
                  <tbody>
                    {bonusList?.length > 0 &&
                      bonusList?.map((item, i) => {
                        return (
                          <tr key={`player-modal-bonus-history ${i}`}>
                            <td>
                              <Form.Check
                                readOnly
                                type='checkbox'
                                label={item?.promotionTitle?.EN}
                                checked={
                                  item?.bonusId === selectedBonus?.bonusId
                                }
                                onClick={() => setSelectedBonus(item)}
                              />
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col style={{ maxWidth: '65%' }}>
              <p
                className='text-center'
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                BONUS DETAILS
              </p>
              <BonusDetailModal
                bonusAmount={bonusAmount}
                bonusDetail={bonusDetail}
                setBonusAmount={setBonusAmount}
                user={userData}
              />
            </Col>
          </Row>
        )}
        {bonusList?.length === 0 && (
          <div className='text-danger text-center'>No Bonuses Found</div>
        )}
      </Modal.Body>
      <Modal.Footer
        className={`d-flex justify-content-${
          bonusList?.length > 0 ? 'between' : 'center'
        }`}
      >
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShowModal(false)
            setBonusAmount('')
          }}
        >
          Cancel
        </Button>
        {bonusList?.length > 0 && (
          <Button
            className='m-2'
            variant='outline-success'
            style={{ width: '75px' }}
            onClick={issueBonusHandler}
          >
            Add
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export const TagModal = ({
  show,
  name,
  updateTags,
  tagOptions,
  selectedTag,
  handleClose,
  setTagOptions,
  setSelectedTag
}) => {
  return (
    <>
      <Formik
        initialValues={{
          tags: []
        }}
        validationSchema={tagSchema}
        onSubmit={(formValues, { resetForm }) => {
          updateTags(formValues)
          resetForm({ formValues: '' })
          handleClose(false)
        }}
      >
        {({ touched, errors, handleSubmit, setFieldValue }) => (
          <Modal
            show={show}
            onHide={() => handleClose(false)}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
              <h4>Manage Tags {name ? ` for ${name}` : ''}</h4>
            </Modal.Header>
            <Modal.Body>
              <div className='form-group'>
                <label
                  htmlFor='reason'
                  className={
                    touched.reason && errors.reason
                      ? 'text-danger mb-2'
                      : 'mb-2'
                  }
                >
                  Tags{' '}
                  <small className='text-danger'>
                    (Only Alphabets and Numbers Allowed)
                  </small>
                </label>
                <CreatableSelect
                  isClearable={false}
                  isMulti
                  name='tags'
                  onCreateOption={(option) => {
                    if (option && option.match(/^[A-Za-z0-9_-]*$/)) {
                      if (tagOptions) {
                        setFieldValue('tags', [
                          ...selectedTag,
                          { label: option, value: option, isNew: true }
                        ])
                        setSelectedTag([
                          ...selectedTag,
                          { label: option, value: option }
                        ])
                        setTagOptions([
                          ...tagOptions,
                          { label: option, value: option, isNew: true }
                        ])
                      } else {
                        setFieldValue('tags', [
                          { label: option, value: option, isNew: true }
                        ])
                        setSelectedTag([{ label: option, value: option }])
                        setTagOptions([
                          { label: option, value: option, isNew: true }
                        ])
                      }
                    } else {
                      toast('Only Alphabets and Numbers Allowed', 'error')
                    }
                  }}
                  options={tagOptions}
                  value={selectedTag}
                  classNamePrefix='select'
                  onChange={(option, e) => {
                    if (e.removedValue?.value !== 'Internal') {
                      if (e?.removedValue?.value) {
                        const tags = selectedTag.filter(
                          (tag) => tag?.value !== e?.removedValue?.value
                        )
                        setSelectedTag(tags)
                      } else {
                        setSelectedTag(option)
                      }
                      setFieldValue('tags', option)
                    }
                  }}
                />
              </div>
            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  )
}

export const VerifyPhoneModal = ({
  userData,
  verifyPhoneModal,
  setVerifyPhoneModal,
  handleYes
}) => {
  return (
    <Modal show={verifyPhoneModal}>
      <Modal.Header>
        <h4>
          Verify Phone Number '{userData?.phoneCode} {userData?.phone}'
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>
          Do you really want to verify{' '}
          <strong>
            '{userData?.phoneCode} {userData?.phone}'
          </strong>{' '}for{' '}
          <strong>
            {userData?.firstName} {userData?.lastName}
          </strong>?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setVerifyPhoneModal(false)
          }}
        >
          No
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleYes}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default IsInternalModal
