import {
  Tabs,
  Tab,
  Row,
  Col,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React, { useState } from 'react'
import GeneralForm from '../../../../Super-Admin/Bonus/components/CreateBonus/general'
import useCreateBonus from '../hooks/useCreateBonus'
import CurrenciesForm from '../../../../Super-Admin/Bonus/components/CreateBonus/currencies'
import { updateTABonusStart } from '../../../../../store/redux-slices/bonus'
import { formatDateYMD } from '../../../../../utils/dateFormatter'
import useEditBonus from '../hooks/useEditBonus'
import Preloader from '../../../../../components/Preloader'
import { tabLabels } from '../../../../Super-Admin/Bonus/components/CreateBonus/constants'
import { bonusSchema } from '../CreateBonus/schema'
import PaymentMethod from '../../../../Super-Admin/Bonus/components/CreateBonus/PaymentMethod'
import WageringContributionForm from '../../../../Super-Admin/Bonus/components/CreateBonus/wageringContribution'
import Games from '../../../../Super-Admin/Bonus/components/CreateBonus/Games'
import { toast } from '../../../../../components/Toast'
import LoyaltySettings from '../../../../Super-Admin/Bonus/components/CreateBonus/LoyaltySettings'
import Languages from '../../../../Super-Admin/Bonus/components/CreateBonus/Languages'
import { safeStringify } from '../../../../../utils/helpers'
import BonusCountry from '../../../../Super-Admin/Bonus/components/CreateBonus/BonusCountry'

const EditBonus = () => {
  const {
    handleImagePreview,
    preview,
    setActiveTab,
    setCurr,
    enableSubmit,
    setEnableSubmit,
    wageringTemplateDetail,
    wageringTemplateList,
    balanceBonusOptions,
    getBalanceBonusesOptions,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    totalPages,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    casinoGamesData,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    languages,
    prepaidsData,
    setPrepaidsData
  } = useCreateBonus()

  const {
    selectedTab,
    setSelectedTab,
    state,
    dispatch,
    bonusDetail,
    bonusId,
    portalOptions,
    loading,
    navigate,
    setState,
    data,
    setData,
    set,
    setGameIds,
    gameIds,
    countryData,
    setCountryData
  } = useEditBonus(limit, pageNo)

  const [loyaltyCount, setLoyaltyCount] = useState(1)

  return (
    <>
      <Row>
        <Col>
          <h3>Edit Bonus </h3>
        </Col>
      </Row>
      {portalOptions.length && !loading && set
        ? (
          <Formik
            enableReinitialize
            initialValues={{
              promotionTitle: bonusDetail?.promotionTitle?.EN || '',
              bonusType: bonusDetail?.bonusType || '',
              validFrom: bonusDetail?.validFrom || state.startDate,
              validTo: bonusDetail?.validTo || state.endDate,
              termCondition: bonusDetail?.termCondition?.EN || '',
              quantity: bonusDetail?.quantity || 0,
              daysToClear: bonusDetail?.daysToClear || 0,
              currency: bonusDetail?.currency || {},
              maxBonusThreshold: '',
              status: bonusDetail?.status || '',
              minDeposit: '',
              wageringRequirementType: bonusDetail?.wageringRequirementType || '',
              maxWinAmount: '',
              isWinCashAmt: '',
              isBonusWagering: '',
              depositBonusPercent: bonusDetail?.depositBonusPercent,
              tenantIds: [{ label: bonusDetail?.tenant?.name, value: bonusDetail?.tenant?.tenantId }],
              validOnDays: bonusDetail?.validOnDays,
              bonusImage: null,
              isActive: bonusDetail?.isActive || false,
              showBonusValidity: bonusDetail?.other?.showBonusValidity,
              visibleInPromotions: bonusDetail?.visibleInPromotions || false,
              isSticky: bonusDetail?.isSticky || false,
              wageringMultiplier: bonusDetail?.wageringMultiplier || 1,
              paymentMethods: { [bonusDetail?.tenantId]: bonusDetail?.paymentMethods } || { [bonusDetail?.tenantId]: [] },
              wageringTemplateId: bonusDetail?.wageringTemplateId || '',
              appliedBonusId: '',
              appliedBonusVal: bonusDetail?.appliedBonusId || '',
              description: bonusDetail?.description?.EN || '',
              loyaltyLevel: bonusDetail?.other?.loyaltyLevel || [{}],
              minBalance: '',
              timePeriod: bonusDetail?.other?.timePeriod || '1',
              other: bonusDetail?.other?.loyaltyLevel || null,
              betLevel: bonusDetail?.other?.betLevel || 1,
              aggregatorId: bonusDetail?.other?.aggregatorId || ''
            }}
            validationSchema={
            bonusSchema(bonusDetail?.currency, { bonusDetail })[tabLabels.findIndex((val) => val === selectedTab)]
          }
            onSubmit={(formValues) => {
              Object.keys(formValues).forEach((key) => {
                if (formValues[key] === null || formValues[key] === '') {
                  delete formValues[key]
                }
              })

              const checkOther = (data) => {
                const other = typeof data === 'object' ? safeStringify(data) : data
                if (safeStringify(bonusDetail?.other) === other) {
                  return {}
                } else {
                  return data
                }
              }
              if (formValues.bonusType === 'promotion') {
                if (selectedTab === 'countries') {
                  const myShowBonusValidity = formValues?.showBonusValidity
                  delete formValues?.showBonusValidity
                  formValues.promotionTitle = safeStringify(data?.promoTitle)
                  formValues.description = safeStringify(data?.desc)
                  formValues.termCondition = safeStringify(data?.terms)
                  delete formValues?.loyaltyLevel
                  delete formValues?.paymentMethods
                  dispatch(
                    updateTABonusStart({
                      data: {
                        ...formValues,
                        bonusId,
                        validFrom: formatDateYMD(state.map((a) => a.startDate)),
                        validTo: formatDateYMD(state.map((a) => a.endDate)),
                        tenantIds: formValues.tenantIds.map((id) => id.value),
                        other: checkOther(safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity }))
                      },
                      navigate
                    })
                  )
                } else {
                  setSelectedTab(selectedTab === 'general'
                    ? (languages?.length ? 'languages' : 'countries')
                    : 'countries')
                }
              } else if (formValues.bonusType === 'freespins') {
                if (selectedTab === 'countries') {
                  const myShowBonusValidity = formValues?.showBonusValidity
                  delete formValues?.showBonusValidity
                  delete formValues?.loyaltyLevel
                  delete formValues?.paymentMethods
                  formValues.gameIds = prepaidsData?.length ? [...Array.from(new Set(prepaidsData?.map(({ gameId }) => gameId))), ...bonusDetail?.gameIds] : gameIds
                  formValues.promotionTitle = safeStringify(data?.promoTitle)
                  formValues.description = safeStringify(data?.desc)
                  formValues.termCondition = safeStringify(data?.terms)
                  gameIds && gameIds?.length
                    ? dispatch(
                      updateTABonusStart({
                        data: {
                          ...formValues,
                          bonusId,
                          validFrom: formatDateYMD(state.map((a) => a.startDate)),
                          validTo: formatDateYMD(state.map((a) => a.endDate)),
                          tenantIds: formValues.tenantIds.map((id) => id.value),
                          other: checkOther(safeStringify({
                            betLevel: formValues?.betLevel,
                            countries: countryData,
                            showBonusValidity: myShowBonusValidity,
                            hub88PrepaidDetails: prepaidsData?.length
                              ? [...bonusDetail?.other?.hub88PrepaidDetails, ...prepaidsData]
                              : null
                          }))
                        },
                        navigate
                      })
                    )
                    : toast('Select At least One Game.', 'error')
                } else {
                  setSelectedTab(selectedTab === 'general'
                    ? languages.length ? 'languages' : bonusDetail?.claimedCount ? 'countries' : 'currency'
                    : (selectedTab === 'languages'
                        ? bonusDetail?.claimedCount ? 'countries' : 'currency'
                        : selectedTab === 'currency' && (formValues?.isSticky === 'true' || formValues?.isSticky)
                          ? 'wageringContribution'
                          : selectedTab === 'wageringContribution' || selectedTab === 'currency' ? 'games' : 'countries'))
                }
              } else {
                if (formValues.bonusType === 'balance') {
                  if (selectedTab === 'countries') {
                    const myShowBonusValidity = formValues?.showBonusValidity
                    delete formValues?.showBonusValidity
                    formValues.promotionTitle = safeStringify(data?.promoTitle)
                    formValues.description = safeStringify(data?.desc)
                    formValues.termCondition = safeStringify(data?.terms)
                    delete formValues?.paymentMethods
                    dispatch(
                      updateTABonusStart({
                        data: {
                          ...formValues,
                          bonusId,
                          validFrom: formatDateYMD(state.map((a) => a.startDate)),
                          validTo: formatDateYMD(state.map((a) => a.endDate)),
                          appliedBonusId: formValues.appliedBonusVal,
                          other: checkOther(safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity }))
                        },
                        navigate
                      })
                    )
                  } else {
                    setSelectedTab(selectedTab === 'general'
                      ? (languages?.length ? 'languages' : 'countries')
                      : 'countries')
                  }
                } else {
                  if (selectedTab === 'countries') {
                    const myShowBonusValidity = formValues?.showBonusValidity
                    delete formValues?.showBonusValidity
                    if (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType) && !bonusDetail?.claimedCount) {
                      for (let i = 0, len = formValues?.loyaltyLevel?.length; i < len; i++) {
                        delete formValues?.loyaltyLevel?.[i].startPoint
                        delete formValues?.loyaltyLevel?.[i].endPoint
                        const parsedFormValue = JSON.parse(JSON.stringify(formValues)) // creating a deep copy to update the ready only fields
                        parsedFormValue.loyaltyLevel[i].bonusPercentage = parsedFormValue?.loyaltyLevel?.[i].bonusPercentage / 100
                        formValues = parsedFormValue
                      }
                    }
                    const timePeriod = formValues?.timePeriod
                    delete formValues?.timePeriod
                    formValues.promotionTitle = safeStringify(data?.promoTitle)
                    formValues.description = safeStringify(data?.desc)
                    formValues.termCondition = safeStringify(data?.terms)
                    formValues.paymentMethods = formValues?.paymentMethods ? safeStringify(formValues?.paymentMethods?.[bonusDetail?.tenantId]) : {}
                    dispatch(
                      updateTABonusStart({
                        data: {
                          ...formValues,
                          bonusId,
                          validFrom: formatDateYMD(state.map((a) => a.startDate)),
                          validTo: formatDateYMD(state.map((a) => a.endDate)),
                          other: checkOther(safeStringify({ loyaltyLevel: formValues?.loyaltyLevel, timePeriod, countries: countryData, showBonusValidity: myShowBonusValidity })),
                          loyaltyLevel: ''
                        },
                        navigate
                      })
                    )
                  } else {
                    setSelectedTab(
                      selected => selected === 'general'
                        ? languages.length ? 'languages' : (formValues?.bonusType !== 'winBoost' ? 'currency' : bonusDetail?.claimedCount ? 'countries' : 'loyalty')
                        : (selected === 'languages'
                            ? (formValues?.bonusType !== 'winBoost'
                                ? (formValues?.bonusType === 'match' && bonusDetail?.claimedCount
                                    ? 'payment'
                                    : (['deposit', 'wagering'].includes(formValues?.bonusType) && bonusDetail?.claimedCount
                                        ? 'countries'
                                        : 'currency'))
                                : bonusDetail?.claimedCount ? 'countries' : 'loyalty')
                            : selected === 'currency'
                              ? (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType)
                                  ? 'loyalty'
                                  : formValues?.bonusType === 'zero_deposit'
                                    ? 'wageringContribution'
                                    : 'payment')
                              : selected === 'loyalty'
                                ? formValues?.bonusType === 'winBoost' ? 'countries' : 'wageringContribution'
                                : selected === 'wageringContribution' ? 'countries' : (formValues?.bonusType === 'match' && bonusDetail?.claimedCount ? 'countries' : 'wageringContribution'))
                    )
                  }
                }
              }
            }}
          >
            {({
              touched,
              errors,
              values,
              setSubmitting,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue
            }) => (
              <BForm>
                <Tabs
                  key={selectedTab}
                  activeKey={selectedTab}
                  className='nav-light'
                  onSelect={(k) => setSelectedTab(k)}
                  mountOnEnter
                  unmountOnExit
                >
                  <Tab disabled eventKey='general' title='General'>
                    <div className='mt-5'>
                      <GeneralForm
                        state={state}
                        setState={setState}
                        isEdit
                        setSelectedTab={setSelectedTab}
                        touched={touched}
                        errors={errors}
                        values={values}
                        setSubmitting={setSubmitting}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        bonusDetail={bonusDetail}
                        handleImagePreview={handleImagePreview}
                        preview={preview}
                        navigate={navigate}
                        portalOptions={portalOptions}
                        getBalanceBonusesOptions={getBalanceBonusesOptions}
                        balanceBonusOptions={balanceBonusOptions}
                        isTenant
                        wageringTemplateList={wageringTemplateList}
                        setEnableSubmit={setEnableSubmit}
                        appliedBonusOptions={appliedBonusOptions}
                        data={data}
                        setData={setData}
                        languages={languages}
                        setCountryData={setCountryData}
                        setPrepaidIds={setPrepaidsData}
                      />
                    </div>
                  </Tab>
                  {languages.length > 0 &&
                    <Tab disabled eventKey='languages' title='Languages'>
                      <div className='mt-5'>
                        <Languages
                          languages={languages}
                          handleSubmit={handleSubmit}
                          setSelectedTab={setSelectedTab}
                          selectedTab={selectedTab}
                          data={data}
                          setData={setData}
                          values={values}
                          isEdit
                          bonusDetail={bonusDetail}
                        />
                      </div>
                    </Tab>}
                  {!['balance', 'promotion', 'winBoost'].includes(values?.bonusType) && !bonusDetail?.claimedCount && (
                    <Tab disabled eventKey='currency' title='Currency'>
                      <div className='mt-5'>
                        <CurrenciesForm
                          isEdit
                          enableSubmit={enableSubmit}
                          setEnableSubmit={setEnableSubmit}
                          touched={touched}
                          errors={errors}
                          values={values}
                          setSubmitting={setSubmitting}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setCurr={setCurr}
                          bonusDetail={bonusDetail}
                          setSelectedTab={setSelectedTab}
                          setActiveTab={setActiveTab}
                          isTenant
                        />
                      </div>
                    </Tab>
                  )}

                  {values.bonusType === 'match' && (
                    <Tab
                      disabled
                      eventKey='payment'
                      title='Applies to Payment method'
                    >
                      <div className='mt-5'>
                        <PaymentMethod
                          touched={touched}
                          errors={errors}
                          values={values}
                          setSubmitting={setSubmitting}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setCurr={setCurr}
                          setSelectedTab={setSelectedTab}
                          setActiveTab={setActiveTab}
                          isEdit
                          isTenant
                          selectedTab={selectedTab}
                          bonusDetail={bonusDetail}
                        />
                      </div>
                    </Tab>
                  )}
                  {['deposit', 'wagering', 'winBoost'].includes(values.bonusType) && !bonusDetail?.claimedCount && (
                    <Tab
                      disabled
                      eventKey='loyalty'
                      title='Loyalty Settings'
                    >
                      <div className='mt-3 hhhhh'>
                        <LoyaltySettings
                          values={values}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          setSelectedTab={setSelectedTab}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          selectedTab={selectedTab}
                          isTenant
                          isEdit
                          loyaltyCount={loyaltyCount}
                          setLoyaltyCount={setLoyaltyCount}
                        />
                      </div>
                    </Tab>
                  )}
                  {!bonusDetail?.claimedCount && (
                    !['balance', 'freespins', 'promotion', 'winBoost'].includes(values.bonusType)
                      ? (
                        <Tab
                          disabled
                          eventKey='wageringContribution'
                          title='Wagering contribution'
                        >
                          <div className='mt-5'>
                            <WageringContributionForm
                              state={state}
                              setState={setState}
                              setSelectedTab={setSelectedTab}
                              touched={touched}
                              errors={errors}
                              values={values}
                              setSubmitting={setSubmitting}
                              handleChange={handleChange}
                              handleSubmit={handleSubmit}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              preview={preview}
                              handleImagePreview={handleImagePreview}
                              navigate={navigate}
                              dispatch={dispatch}
                              wageringTemplateList={wageringTemplateList}
                              wageringTemplateDetail={wageringTemplateDetail}
                              isTenant
                              limit={limit}
                              pageNo={pageNo}
                              setLimit={setLimit}
                              setPageNo={setPageNo}
                              totalPages={totalPages}
                              selectedTab={selectedTab}
                              isEdit
                            />
                          </div>
                        </Tab>
                        )
                      : values?.isSticky && !bonusDetail?.claimedCount &&
                        <Tab
                          disabled
                          eventKey='wageringContribution'
                          title='Wagering contribution'
                        >
                          <div className='mt-5'>
                            <WageringContributionForm
                              state={state}
                              setState={setState}
                              setSelectedTab={setSelectedTab}
                              touched={touched}
                              errors={errors}
                              values={values}
                              setSubmitting={setSubmitting}
                              handleChange={handleChange}
                              handleSubmit={handleSubmit}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              preview={preview}
                              handleImagePreview={handleImagePreview}
                              navigate={navigate}
                              dispatch={dispatch}
                              wageringTemplateList={wageringTemplateList}
                              wageringTemplateDetail={wageringTemplateDetail}
                              isTenant
                              limit={limit}
                              pageNo={pageNo}
                              setLimit={setLimit}
                              setPageNo={setPageNo}
                              totalPages={totalPages}
                              selectedTab={selectedTab}
                              isEdit
                            />
                          </div>
                        </Tab>
                  )}
                  {(values.bonusType === 'freespins') && !bonusDetail?.claimedCount && (
                    <Tab disabled eventKey='games' title='Games'>
                      <div className='mt-5'>
                        <Games
                          gameIds={gameIds}
                          setGameIds={setGameIds}
                          setLimit={setGamesLimit}
                          setPageNo={setGamesPageNo}
                          setSearch={setSearch}
                          search={search}
                          totalPages={totalPages}
                          limit={gamesLimit}
                          pageNo={gamesPageNo}
                          loading={loading}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          casinoGamesData={casinoGamesData}
                          selectedProvider={selectedProvider}
                          setSelectedProvider={setSelectedProvider}
                          setSelectedTab={setSelectedTab}
                          isEdit
                          errors={errors}
                          isTenant
                          values={values}
                          prepaidsData={prepaidsData}
                          setPrepaidsData={setPrepaidsData}
                        />
                      </div>
                    </Tab>
                  )}
                  <Tab disabled eventKey='countries' title='Countries'>
                    <div className='mt-5'>
                      <BonusCountry
                        data={countryData}
                        setData={setCountryData}
                        values={values}
                        setSelectedTab={setSelectedTab}
                        handleSubmit={handleSubmit}
                        languages={languages}
                        isTenant
                        isEdit
                        bonusDetail={bonusDetail}
                      />
                    </div>
                  </Tab>
                </Tabs>
              </BForm>
            )}
          </Formik>
          )
        : (
          <Preloader />
          )}
    </>
  )
}

export default EditBonus
