import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useDidMountEffect from '../../../../../utils/useDidMountEffect'
import { getBonusStart, getWageringTemplateDetailsStart } from '../../../../../store/redux-slices/bonus'
import { getPaymentProviderStart } from '../../../../../store/redux-slices/payment'
import { getFreeSpinGamesStart } from '../../../../../store/redux-slices/superAdminCasinoManagement'
import { getLanguagesStart } from '../../../../../store/redux-slices/languages'

const useBonusDetail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bonusId } = useParams()
  const [selectedTab, setSelectedTab] = useState('general')
  const { loading, bonusDetail } = useSelector((state) => state.bonus)
  const { paymentProviders } = useSelector(state => state.payment)
  const { wageringTemplateDetail } = useSelector(state => state.bonus)
  const [pageNo, setPageNo] = useState(1)
  const [limit, setLimit] = useState(15)
  const [countryData, setCountryData] = useState([])
  const [gamesPageNo, setGamesPageNo] = useState(1)
  const [gamesLimit, setGamesLimit] = useState(15)
  const totalPages = Math.ceil(wageringTemplateDetail?.gameDetail?.count / limit)
  const isInitialRender = useDidMountEffect()
  const [search, setSearch] = useState('')
  const [wagerSearch, setWagerSearch] = useState('')
  const [selectedProvider, setSelectedProvider] = useState('')
  const [gameIds, setGameIds] = useState(bonusDetail?.gameIds || [])
  const { casinoGamesData } = useSelector((state) => state.superAdminCasino)
  const { languages } = useSelector((state) => state.languages)
  const [selectedLang, setSelectedLang] = useState('EN')

  useEffect(() => {
    dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
  }, [])

  useEffect(() => {
    if (bonusId) {
      dispatch(getBonusStart({ bonusId, userBonusId: '', isTenant: false }))
    }
  }, [bonusId])

  useEffect(() => {
    if (bonusDetail) {
      setCountryData(bonusDetail?.other?.countries)
      !bonusDetail?.other?.hub88PrepaidDetails?.length && setGameIds(bonusDetail?.gameIds)
      const key = Object.keys(bonusDetail?.promotionTitle)
      setSelectedLang(key?.[1] !== 'EN' ? key?.[1] : key?.[0])
      bonusDetail?.bonusType === 'match' &&
        dispatch(getPaymentProviderStart({
          isTenant: false,
          paymentType: 'deposit',
          aggregator: '',
          tenant: '',
          group: '',
          status: true,
          tenantIds: JSON.stringify([bonusDetail?.tenantId]),
          adminId: '',
          tenantId: ''
        }))
    }
  }, [bonusDetail])

  const fetchTemplateDetails = () => {
    dispatch(
      getWageringTemplateDetailsStart({
        wageringTemplateId: bonusDetail?.WageringTemplate?.wageringTemplateId,
        pageNo,
        limit,
        providerId: '',
        search: wagerSearch
      })
    )
  }

  useEffect(() => {
    if (bonusDetail?.WageringTemplate?.wageringTemplateId) {
      setPageNo(1)
      fetchTemplateDetails()
    }
  }, [bonusDetail?.WageringTemplate?.wageringTemplateId, limit])

  useEffect(() => {
    if (bonusDetail?.WageringTemplate?.wageringTemplateId) {
      !isInitialRender && fetchTemplateDetails()
    }
  }, [pageNo])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          bonusDetail?.WageringTemplate?.wageringTemplateId && fetchTemplateDetails()
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [wagerSearch])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          bonusDetail && bonusDetail?.bonusId === parseInt(bonusId) && bonusDetail?.bonusType === 'freespins' &&
          dispatch(
            getFreeSpinGamesStart({
              limit: gamesLimit,
              pageNo: gamesPageNo,
              search,
              providerId: selectedProvider,
              bonusId
            })
          )
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    bonusDetail && bonusDetail?.bonusId === parseInt(bonusId) && bonusDetail?.bonusType === 'freespins' &&
    dispatch(
      getFreeSpinGamesStart({
        limit: gamesLimit,
        pageNo: gamesPageNo,
        search,
        providerId: selectedProvider,
        bonusId
      })
    )
  }, [selectedProvider, gamesLimit, gamesPageNo, bonusDetail])

  return {
    selectedTab,
    setSelectedTab,
    bonusDetail,
    loading,
    bonusId,
    paymentProviders,
    navigate,
    wageringTemplateDetail,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    casinoGamesData,
    gameIds,
    setGameIds,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    wagerSearch,
    setWagerSearch,
    selectedLang,
    setSelectedLang,
    languages,
    setCountryData,
    countryData
  }
}

export default useBonusDetail
