import {
  faCheck,
  faCopy,
  faEdit,
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Tabs,
  Tab,
  Row,
  Col,
  Button,
  ButtonGroup,
  Badge,
  Form
} from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../../../../components/OverlayTrigger'
import Preloader from '../../../../components/Preloader'
import { convertAmountOptions } from '../components/CreateBonus/constants'
import useBonusDetail from '../components/hooks/useBonusDetail'
import GeneralDetails from '../../../Super-Admin/Bonus/BonusDetail/generalDetail'
import WageringTemplateDetails from '../../../../components/WageringTemplateDetails'
import Games from '../../../Super-Admin/Bonus/components/CreateBonus/Games'
import useCheckPermission from '../../../../utils/checkPermission'
import LoyaltyPoint from '../../../../components/LoyaltyPoints/LoyaltyPoint'
import LanguageDetails from '../../../Super-Admin/Bonus/BonusDetail/LanguageDetails'
import BonusCountry from '../../../Super-Admin/Bonus/components/CreateBonus/BonusCountry'

const BonusDetail = () => {
  const {
    selectedTab,
    setSelectedTab,
    bonusDetail,
    loading,
    bonusId,
    navigate,
    paymentProviders,
    wageringTemplateDetail,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    selectedProvider,
    setSelectedProvider,
    search,
    setSearch,
    gameIds,
    setGameIds,
    casinoGamesData,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    wagerSearch,
    setWagerSearch,
    selectedLang,
    setSelectedLang,
    languages,
    countryData,
    setCountryData
  } = useBonusDetail()
  const { isHidden } = useCheckPermission()

  return (
    <>
      {loading && !bonusDetail
        ? (
          <Preloader />
          )
        : (
          <>
            <Row>
              <Col className='d-flex'>
                <Col>
                  {' '}
                  <h3>Bonus Details</h3>
                </Col>
                <Col className='d-flex justify-content-end align-items-center'>
                  <ButtonGroup>
                    <Trigger message='Edit'>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='info'
                        hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                        onClick={() =>
                          navigate(`/tenant/bonus/edit/${bonusId}`)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </Trigger>
                  </ButtonGroup>
                  <ButtonGroup>
                    <Trigger message='Clone'>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='danger'
                        hidden={isHidden({ module: { key: 'Bonus', value: 'C' } })}
                        onClick={() =>
                          navigate(`/tenant/bonus/clone/${bonusId}`)}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </Trigger>
                  </ButtonGroup>
                </Col>
              </Col>
            </Row>
            <Tabs
              key={selectedTab}
              activeKey={selectedTab}
              className='nav-light'
              onSelect={(k) => setSelectedTab(k)}
            >
              <Tab eventKey='general' title='General'>
                <div className='mt-5'>
                  <GeneralDetails bonusDetail={bonusDetail} isTenant selectedLang={selectedLang} />
                </div>
              </Tab>
              {bonusDetail?.promotionTitle && Object.keys(bonusDetail?.promotionTitle)?.length > 1 &&
                <Tab eventKey='languages' title='Languages'>
                  <div className='mt-5'>
                    <LanguageDetails
                      languages={languages}
                      selectedLang={selectedLang}
                      setSelectedLang={setSelectedLang}
                      bonusDetail={bonusDetail}
                      isTenant
                    />
                  </div>
                </Tab>}
              {!['balance', 'promotion', 'winBoost'].includes(bonusDetail?.bonusType) && (
                <Tab eventKey='currency' title='Currency'>
                  <div className='mt-5'>
                    <Row>
                      <h3>Currencies</h3>
                      {bonusDetail?.currency &&
                      Object.keys(bonusDetail.currency).length > 0 &&
                      Object.keys(bonusDetail.currency).map((key, index) => (
                        <Row key={index} className='g-2 mb-2'>
                          <Col sm='auto' className='d-flex w-100'>
                            <Col className='px-1 text-center'>
                              {index < 1 && (
                                <label htmlFor='currency' style={{ fontSize: '14px' }}>Currency</label>
                              )}
                              <p>{key}</p>
                            </Col>
                            {Object.keys(bonusDetail.currency[key]).map(
                              (currKey, currIndex) => {
                                const hide = (['deposit', 'wagering', 'zero_deposit'].includes(bonusDetail?.bonusType)
                                  ? (currKey === 'minDeposit' || currKey === 'maxBonusThreshold' || currKey === 'maxWinAmount')
                                  : ((bonusDetail?.bonusType === 'freespins' || bonusDetail?.bonusType === 'cashfreespins'))
                                      ? ((bonusDetail?.isSticky === 'true' || bonusDetail?.isSticky) ? (currKey !== 'minDeposit' && currKey !== 'maxWinAmount' && currKey !== 'zeroOutThreshold' && currKey !== 'maxBetAllowed') : (currKey !== 'minDeposit' && currKey !== 'maxWinAmount' && currKey !== 'maxBetAllowed'))
                                      : currKey === 'minBalance')
                                return (
                                  currKey !== 'minBonusThreshold' &&
                                    <Col
                                      className='px-1 text-center'
                                      key={currIndex}
                                      hidden={hide}
                                    >
                                      {index < 1 && (
                                        <label htmlFor={currKey} style={{ fontSize: '14px' }}>
                                          {
                                          ['deposit', 'wagering'].includes(bonusDetail?.bonusType)
                                            ? convertAmountOptions?.find(
                                              (val) => currKey === 'minBalance' ? val.value === 'minBalanceCash' : val.value === currKey
                                            )?.label
                                            : convertAmountOptions?.find(
                                              (val) => val.value === currKey
                                            )?.label
                                        }
                                        </label>
                                      )}

                                      <Form.Control
                                        value={bonusDetail.currency[key][currKey]}
                                        hidden={hide}
                                        disabled
                                      />
                                    </Col>
                                )
                              }
                            )}
                          </Col>
                        </Row>
                      ))}

                      <Col />
                    </Row>
                  </div>
                </Tab>
              )}
              {bonusDetail?.bonusType === 'match' && (
                <Tab eventKey='payment' title='Applies to Payment method'>
                  <div className='mt-5'>
                    {paymentProviders &&
                      Object.keys(paymentProviders)?.map(
                        (tenantId) => {
                          return (
                            <Row key={tenantId} className='mb-2'>
                              <h5>Portal:&nbsp;{`${bonusDetail?.tenant?.name}(${bonusDetail?.tenant?.domain})`} </h5>
                              {paymentProviders[tenantId]?.map(({ name }, index) => (
                                <Col sm={2} key={index} className='d-flex'>
                                  <Badge
                                    className='mb-3 mt-1 rounded-circle'
                                    style={{ height: 'fit-content' }}
                                    bg={
                                    bonusDetail?.paymentMethods?.find(obj => obj.name === name)
                                      ? 'success'
                                      : 'dark'
                                  }
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                      bonusDetail?.paymentMethods?.find(obj => obj.name === name)
                                        ? faCheck
                                        : faTimes
                                    }
                                    />
                                  </Badge>

                                  <span className='mx-2'> {name}</span>
                                </Col>
                              ))}
                            </Row>
                          )
                        }
                      )}
                  </div>
                </Tab>
              )}

              {['deposit', 'wagering', 'winBoost'].includes(bonusDetail?.bonusType) && (
                <Tab
                  eventKey='loyalty'
                  title='Loyalty Settings'
                >
                  <div className='mt-5 hhhhh'>
                    <LoyaltyPoint
                      values={bonusDetail?.other}
                      details
                      isTenant
                    />
                  </div>
                </Tab>
              )}

              {bonusDetail?.bonusType !== 'balance' && (
                !['freespins', 'promotion', 'winBoost'].includes(bonusDetail?.bonusType)
                  ? (
                    <Tab
                      eventKey='wageringContribution'
                      title='Wagering contribution'
                    >
                      <div className='mt-5'>
                        <WageringTemplateDetails
                          wageringTemplateDetail={wageringTemplateDetail}
                          limit={limit}
                          pageNo={pageNo}
                          setLimit={setLimit}
                          setPageNo={setPageNo}
                          totalPages={totalPages}
                          search={wagerSearch}
                          setSearch={setWagerSearch}
                        />
                      </div>
                    </Tab>
                    )
                  : bonusDetail?.isSticky && (
                    <Tab
                      eventKey='wageringContribution'
                      title='Wagering contribution'
                    >
                      <div className='mt-5'>
                        <WageringTemplateDetails
                          wageringTemplateDetail={wageringTemplateDetail}
                          limit={limit}
                          pageNo={pageNo}
                          setLimit={setLimit}
                          setPageNo={setPageNo}
                          totalPages={totalPages}
                          search={wagerSearch}
                          setSearch={setWagerSearch}
                        />
                      </div>
                    </Tab>
                  )
              )}
              {(bonusDetail?.bonusType === 'freespins') && (
                <Tab eventKey='games' title='Games'>
                  <div className='mt-5'>
                    <Games
                      gameIds={gameIds}
                      setGameIds={setGameIds}
                      setLimit={setGamesLimit}
                      setPageNo={setGamesPageNo}
                      setSearch={setSearch}
                      search={search}
                      totalPages={totalPages}
                      limit={gamesLimit}
                      pageNo={gamesPageNo}
                      loading={loading}
                      casinoGamesData={casinoGamesData}
                      selectedProvider={selectedProvider}
                      setSelectedProvider={setSelectedProvider}
                      setSelectedTab={setSelectedTab}
                      isTenant
                      details
                    />
                  </div>
                </Tab>
              )}
              <Tab eventKey='countries' title='Countries'>
                <div className='mt-5'>
                  <BonusCountry
                    data={countryData}
                    setData={setCountryData}
                    details
                    languages={languages}
                    isTenant
                  />
                </div>
              </Tab>
            </Tabs>
          </>
          )}
    </>
  )
}
export default BonusDetail
