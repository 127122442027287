import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import { getCurrencyWiseWithdrawalStart, getPlayerLiabilityStart } from '../../../../store/redux-slices/dashboard'
import { checkIsTenant } from '../../../../utils/constants'

const usePlayerLiability = ({ selectedPortal = '', selectedClient = '', accordionState }) => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()

  const { playerLiabilityLoading: loading, playerLiabilityData, currencyWiseWithdrawal } = useSelector((state) => state.dashboard)
  const [dateOptions, setDateOptions] = useState('today')
  const [selectedTab, setSelectedTab] = useState('playerLiability')
  const [state, setState] = useState(formatDateYMD(new Date()))
  const [withdrawalTotalAmount, setWithdrawalTotalAmount] = useState(0)

  const loadPlayerLiabilitiesTabWise = () => {
    if (accordionState.playerLiabilityReport) {
      if (selectedTab === 'playerLiability') {
        dispatch(getPlayerLiabilityStart({
          isTenant,
          startDate: formatDateYMD(state),
          endDate: formatDateYMD(state),
          tenantId: selectedPortal,
          adminId: selectedClient
        }))
      } else {
        dispatch(getCurrencyWiseWithdrawalStart({
          isTenant,
          startDate: formatDateYMD(state),
          endDate: formatDateYMD(state),
          tenantId: selectedPortal,
          adminId: selectedClient
        }))
      }
    }
  }

  const loadPlayerLiabilities = () => {
    if (accordionState.playerLiabilityReport) {
      dispatch(getPlayerLiabilityStart({
        isTenant,
        startDate: formatDateYMD(state),
        endDate: formatDateYMD(state),
        tenantId: selectedPortal,
        adminId: selectedClient
      }))
      dispatch(getCurrencyWiseWithdrawalStart({
        isTenant,
        startDate: formatDateYMD(state),
        endDate: formatDateYMD(state),
        tenantId: selectedPortal,
        adminId: selectedClient
      }))
    }
  }

  useEffect(() => {
    loadPlayerLiabilities()
  }, [accordionState.playerLiabilityReport])

  useEffect(() => {
    if (currencyWiseWithdrawal) {
      const summedAmount = currencyWiseWithdrawal.reduce((total, withdrawal) => {
        return total + withdrawal.primaryCurrencyAmount
      }, 0)
      setWithdrawalTotalAmount(summedAmount)
    }
  }, [currencyWiseWithdrawal])

  useEffect(() => {
    loadPlayerLiabilitiesTabWise()
  }, [state, selectedClient, selectedPortal, selectedTab])

  const getCsvDownloadUrl = () => {
    if (selectedTab === 'playerLiability') return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/players-liability?${!isTenant ? `adminId=${selectedClient}&` : ''}tenantId=${isTenant ? getItem('tenant-id') : selectedPortal}&startDate=${formatDateYMD(state)}&endDate=${formatDateYMD(state)}&csvDownload=true&token=${getLoginToken()}`
    else return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/currency-wise-withdrawals?${!isTenant ? `adminId=${selectedClient}&` : ''}tenantId=${isTenant ? getItem('tenant-id') : selectedPortal}&startDate=${formatDateYMD(state)}&endDate=${formatDateYMD(state)}&csvDownload=true&token=${getLoginToken()}`
  }

  return {
    state,
    loading,
    setState,
    dateOptions,
    selectedTab,
    setDateOptions,
    setSelectedTab,
    getCsvDownloadUrl,
    playerLiabilityData,
    loadPlayerLiabilities,
    withdrawalTotalAmount,
    currencyWiseWithdrawal
  }
}

export default usePlayerLiability
