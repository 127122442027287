import { Col, Row, Table } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PaginationComponent from '../../../../../components/Pagination'
import PrepaidsModal from './PrepaidsModal'
import PrepaidDetails from '../../BonusDetail/PrepaidDetails'

const GamesListEdit = ({
  gameIds,
  casinoGamesData,
  setGameIds,
  page,
  limit,
  setLimit,
  setPage,
  isTenant = false,
  isEdit,
  details,
  values = {},
  isHub88 = false,
  prepaidsData,
  setPrepaidsData
}) => {
  const [show, setShow] = useState('')
  const [data, setData] = useState('')
  const [gameId, setGameId] = useState('')
  const totalPages = Math.ceil(casinoGamesData?.count / limit)
  const { bonusDetail } = useSelector((state) => state.bonus)
  const { allProviders } = useSelector((state) => isTenant ? state.tenantCasino : state.superAdminCasino)

  const selectHandler = (e, masterCasinoGameId) => {
    if (gameIds?.includes('' + masterCasinoGameId) || gameIds?.includes(masterCasinoGameId)) {
      const newObj = []
      for (const ids in gameIds) {
        if (isHub88 ? gameIds[ids] !== masterCasinoGameId : parseInt(gameIds[ids]) !== masterCasinoGameId) {
          newObj.push(gameIds[ids])
        }
      }
      setGameIds(newObj)
      if (isHub88) {
        setPrepaidsData(prepaidsData?.filter(({ game_code }) => game_code !== masterCasinoGameId))
      }
    } else {
      const newObj = []
      for (const ids in gameIds) {
        newObj.push(gameIds[ids])
      }
      newObj.push(masterCasinoGameId)
      setGameIds(newObj)
    }
  }

  return (
    <>
      <div className='wagering-table'>
        <Row className='d-flex'>
          <Col>
            <Table bordered striped responsive hover size='sm' className='text-center scrollable'>
              <thead className='thead-dark'>
                <tr>
                  {[
                    'Game Id',
                    'Name',
                    'Provider'
                  ].map((h) => (<th key={h}>{h}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {casinoGamesData && casinoGamesData.count > 0
                  ? casinoGamesData.rows.map(({ masterCasinoGameId, name, masterCasinoProviderId, identifier }, idx) => {
                    return (
                      <tr key={`wagering-template ${masterCasinoGameId}`}>
                        <td>
                          <input
                            className='wageering-template-checkbox'
                            type='checkbox'
                            defaultChecked={isHub88 ? gameIds?.includes(identifier) : (gameIds?.includes(masterCasinoGameId) || gameIds?.includes(String(masterCasinoGameId)))}
                            checked={isHub88 ? gameIds?.includes(identifier) : (gameIds?.includes(masterCasinoGameId) || gameIds?.includes(String(masterCasinoGameId)))}
                            name={masterCasinoGameId}
                            disabled={details}
                            onChange={(e) => { !details && selectHandler(e, isHub88 ? identifier : masterCasinoGameId) }}
                          />
                          {masterCasinoGameId}
                        </td>

                        <td
                          className={details && bonusDetail?.other?.hub88PrepaidDetails ? 'text-link cursor-pointer' : ''}
                          onClick={() => {
                            if (details && bonusDetail?.other?.hub88PrepaidDetails) {
                              setData(identifier)
                              setShow(true)
                            }
                          }}
                        >{name}
                        </td>

                        <td>{(allProviders?.rows?.length ? allProviders.rows : allProviders)?.find(obj => obj.masterCasinoProviderId === masterCasinoProviderId)?.name}</td>

                      </tr>
                    )
                  })
                  : (
                    <tr>
                      <td colSpan={5} className='text-danger text-center'>
                        No data found
                      </td>
                    </tr>
                    )}

              </tbody>
            </Table>
          </Col>

          {isHub88 &&
            <Col xs={3}>
              <Table bordered striped responsive hover size='sm' className='text-center scrollable'>
                <thead className='thead-dark'>
                  <tr>
                    {[
                      'Game Id'
                    ].map((h) => (<th key={h}>{h}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {gameIds && gameIds.length > 0
                    ? gameIds.map((id, idx) => {
                      return (
                        <tr key={`wagering-template ${id}`}>
                          <td
                            className={`${!prepaidsData?.filter(({ game_code }) => game_code === id)?.length ? 'cursor-pointer text-blue' : ''}`}
                          >
                            <a
                              href={() => false}
                              onClick={e => {
                                e.stopPropagation()
                                setData(id)
                                setGameId(casinoGamesData?.rows?.filter(({ identifier }) => identifier === id)?.[0]?.masterCasinoGameId)
                                !prepaidsData?.filter(({ game_code }) => game_code === id)?.length && setShow(true)
                              }}
                            >{id}
                            </a>
                          </td>

                        </tr>
                      )
                    })
                    : (
                      <tr>
                        <td colSpan={5} className='text-danger text-center'>
                          No data found
                        </td>
                      </tr>
                      )}

                </tbody>
              </Table>
            </Col>}
        </Row>

        {casinoGamesData?.count !== 0 && (
          <PaginationComponent
            page={casinoGamesData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </div>

      {!details && show &&
        <PrepaidsModal
          show={show}
          data={data}
          gameId={gameId}
          values={values}
          setData={setData}
          setShow={setShow}
          prepaidsData={prepaidsData}
          setPrepaidsData={setPrepaidsData}
        />}

      {details && show &&
        <PrepaidDetails
          show={show}
          data={data}
          setShow={setShow}
          hub88Details={bonusDetail?.other?.hub88PrepaidDetails?.filter(({ game_code }) => game_code === data)}
        />}

    </>
  )
}

export default GamesListEdit
