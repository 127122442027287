import { Row, Col, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import React from 'react'
import Select from 'react-select'
import { formatDateYMD } from '../../utils/dateFormatter'
import './FormFields.scss'

const FormFields = ({
  values,
  isHub88,
  handleChange,
  handleBlur,
  bonusOptions,
  setFieldValue,
  segmentsOptions,
  excludeSegmentsOptions,
  setExcludeSegmentOptions
}) => {
  return (
    <>
      <Row>
        {!isHub88 &&
          <>
            <Col xs='auto' className='mt-3'>
              <BForm.Label>
                Spins <span className='text-danger'>* </span>
              </BForm.Label>
              <BForm.Control
                type='number'
                name='quantity'
                min={1}
                placeholder='Enter Spins'
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <ErrorMessage
                component='div'
                name='quantity'
                className='text-danger'
              />
            </Col>
            <Col xs='auto' className='mt-3'>
              <BForm.Label>
                Bet Level <span className='text-danger'>* </span>
              </BForm.Label>
              <BForm.Control
                type='number'
                min={1}
                name='betLevel'
                placeholder='Enter Bet Level'
                value={values.betLevel}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <ErrorMessage
                component='div'
                name='betLevel'
                className='text-danger'
              />
            </Col>
          </>}
        <Col xs='auto' className='mt-3'>
          <BForm.Label>
            Valid From <span className='text-danger'>* </span>
          </BForm.Label>
          <BForm.Control
            type='date'
            name='validFrom'
            min={formatDateYMD(new Date())}
            value={values.validFrom}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name='validFrom'
            className='text-danger'
          />
        </Col>
        <Col xs='auto' className='mt-3'>
          <BForm.Label>
            Valid To <span className='text-danger'>* </span>
          </BForm.Label>
          <BForm.Control
            type='date'
            name='validTo'
            min={formatDateYMD(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))}
            value={values.validTo}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component='div'
            name='validTo'
            className='text-danger'
          />
        </Col>
        <Col xs='auto' className='mt-3'>
          <BForm.Label>
            Bonus
            <span className='text-danger'>* </span>
          </BForm.Label>

          <Select
            isClearable={false}
            name='bonusId'
            options={bonusOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(option, e) => {
              setFieldValue(
                'bonusId',
                option.value
              )
            }}
          />

          <ErrorMessage
            component='div'
            name='bonusId'
            className='text-danger'
          />
        </Col>
      </Row>
      {segmentsOptions
        ? (
          <Row className='mt-3'>
            <Col xs='auto' className='mt-3'>
              <BForm.Label>
                Segments <span className='text-danger'>* </span>
              </BForm.Label>
              <Select
                isMulti
                isClearable={false}
                name='includeUserIds'
                options={segmentsOptions}
                className='basic-multi-select'
                classNamePrefix='select'
                onChange={(option, e) => {
                  setFieldValue('includeUserIds', option)
                  const currData = option.map(item => item.value)
                  let opt = []
                  if (e === 'selected-action') {
                    opt = excludeSegmentsOptions
                  } else {
                    opt = segmentsOptions
                  }
                  const prevData = opt.map(item => item.value)
                  const nextData = prevData.filter(item => currData.includes(item))
                  setExcludeSegmentOptions(opt.filter(item => !nextData.includes(item.value)))
                  setFieldValue('excludeUserIds', values?.excludeUserIds?.filter(item => !currData.includes(item.value)))
                }}
              />
              <ErrorMessage
                component='div'
                name='includeUserIds'
                className='text-danger'
              />
            </Col>
            <Col xs='auto' className='mt-3'>
              <BForm.Label>
                Exclude Segments
              </BForm.Label>
              <Select
                isMulti
                isClearable={false}
                name='excludeUserIds'
                options={excludeSegmentsOptions}
                value={values?.excludeUserIds}
                className='basic-multi-select'
                classNamePrefix='select'
                onChange={(option) => {
                  setFieldValue('excludeUserIds', option)
                }}
              />
            </Col>
          </Row>)
        : ''}
    </>
  )
}

export default FormFields
