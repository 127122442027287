import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLivePlayersReportStart } from '../../../../store/redux-slices/dashboard'
import { checkIsTenant } from '../../../../utils/constants'

const useLivePlayers = ({ selectedPortal = '', selectedClient = '' }) => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()

  const { livePlayerLoading: loading, livePlayerData } = useSelector((state) => state.dashboard)
  const [count, setCount] = useState(0)
  const { adminPermissions } = useSelector(state => state.admins)
  const permissionKeys = adminPermissions && Object.keys(adminPermissions)

  const fetchDetails = () => {
    dispatch(getLivePlayersReportStart({
      isTenant,
      tenantId: selectedPortal,
      adminId: selectedClient
    }))
  }

  useEffect(() => {
    fetchDetails()
  }, [selectedPortal, selectedClient])

  useEffect(() => {
    if (permissionKeys.includes('LivePlayerReport')) {
      const intervals = setInterval(() => {
        clearInterval(intervals)
        fetchDetails()
        setCount(count + 1)
      }, 30000)
    }
  }, [count])

  return {
    loading,
    fetchDetails,
    livePlayerData
  }
}

export default useLivePlayers
