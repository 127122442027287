import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllClientsStart, getAllPortalsStart } from '../../../../../store/redux-slices/tenants'
import { createBonusStart, getWageringTemplateStart } from '../../../../../store/redux-slices/bonus'
import { formatDateYMD } from '../../../../../utils/dateFormatter'
import { getBalanceBonuses } from '../../../../../utils/apiCalls'
import { toast } from '../../../../../components/Toast'
import { getloyaltyLevelStart } from '../../../../../store/redux-slices/superAdminSettings'
import { safeStringify } from '../../../../../utils/helpers'

const useCreateBonus = (bonusDetail, isClone) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [set, setSet] = useState(false)
  const [selectedTab, setSelectedTab] = useState('general')
  const [selectedClient, setSelectedClient] = useState('')
  const { clientsFilterData, portalsFilterData } = useSelector(state => state.tenants)
  const { wageringTemplateList, loading, languages } = useSelector(state => state.bonus)
  const { wageringTemplateDetail } = useSelector(state => state.wageringTemplate)
  const [activeTab, setActiveTab] = useState(0)
  const [portalOptions, setPortalOptions] = useState([])
  const [providerOptions, setProviderOptions] = useState([])
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [balanceBonusOptions, setBalanceBonusOptions] = useState(null)
  const [appliedBonusOptions, setAppliedBonusOptions] = useState({ label: '', value: '', country: [] })
  const [pageNo, setPageNo] = useState(1)
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState('')
  const [gameIds, setGameIds] = useState(bonusDetail?.gameIds || [])
  const [selectedProvider, setSelectedProvider] = useState('')
  const totalPages = Math.ceil(wageringTemplateDetail?.gameDetail?.count / limit)
  const { loyaltyLevel } = useSelector(state => state.superAdminSettings)
  const [gamesPageNo, setGamesPageNo] = useState(1)
  const [gamesLimit, setGamesLimit] = useState(15)
  const [loyaltyCount, setLoyaltyCount] = useState(1)
  const [countryData, setCountryData] = useState([])
  const [data, setData] = useState({
    promoTitle: bonusDetail?.promotionTitle || { EN: '' },
    desc: bonusDetail?.description || { EN: '' },
    terms: bonusDetail?.termCondition || { EN: '' }
  })

  const [prepaidsData, setPrepaidsData] = useState([])

  useEffect(() => {
    if (bonusDetail) {
      setData({
        promoTitle: bonusDetail?.promotionTitle,
        desc: bonusDetail?.description,
        terms: bonusDetail?.termCondition
      })
      setSet(true)
    }
  }, [bonusDetail])

  const { casinoProvidersData, casinoGamesData } = useSelector((state) => state.superAdminCasino)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const [curr, setCurr] = useState({
    EUR: {
      maxBonusThreshold: '',
      minDeposit: '',
      maxWinAmount: ''
    }
  })

  useEffect(() => {
    dispatch(getloyaltyLevelStart({ isTenant: false }))
  }, [])

  const getBalanceBonusesOptions = async (tenantId) => {
    await getBalanceBonuses({ tenantId }).then((data) => {
      setBalanceBonusOptions(data?.data?.data?.bonusDetails)
    })
  }

  useEffect(() => {
    if (bonusDetail) {
      dispatch(getWageringTemplateStart({ isTenant: false, adminId: bonusDetail?.adminId }))
    }
  }, [bonusDetail?.adminId])

  const handelCreateBonus = (formValues) => {
    Object.keys(formValues).forEach((key) => {
      if (formValues[key] === null || formValues[key] === '') {
        delete formValues[key]
      }
    })
    const checkOther = (data) => {
      const other = typeof data === 'object' ? safeStringify(data) : data
      if (safeStringify(bonusDetail?.other) === other) {
        return {}
      } else {
        return data
      }
    }
    if (formValues.bonusType === 'promotion') {
      if (selectedTab === 'countries') {
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods
        formValues?.appliedBonusId && delete formValues?.appliedBonusId
        dispatch(
          createBonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other: checkOther(safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity }))
            },
            navigate
          })
        )
      } else {
        setSelectedTab(selectedTab === 'general'
          ? (languages?.length ? 'languages' : 'countries')
          : 'countries')
      }
    } else if (formValues.bonusType === 'freespins' || formValues?.bonusType === 'cashfreespins') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods
        formValues?.appliedBonusId && delete formValues?.appliedBonusId
        formValues.gameIds = prepaidsData?.length ? Array.from(new Set(prepaidsData?.map(({ gameId }) => gameId))) : gameIds
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        gameIds && gameIds?.length
          ? dispatch(
            createBonusStart({
              data: {
                ...formValues,
                bonusType: 'freespins',
                validFrom: formatDateYMD(state.map((a) => a.startDate)),
                validTo: formatDateYMD(state.map((a) => a.endDate)),
                tenantIds: formValues.tenantIds.map((id) => id.value),
                other: checkOther(safeStringify({
                  betLevel: formValues?.betLevel,
                  countries: countryData,
                  showBonusValidity: myShowBonusValidity,
                  hub88PrepaidDetails: prepaidsData?.length
                    ? prepaidsData
                    : null
                }))
              },
              navigate
            })
          )
          : toast('Select At least One Game.', 'error')
      } else {
        setSelectedTab(selectedTab === 'general'
          ? languages.length ? 'languages' : 'currency'
          : (selectedTab === 'languages'
              ? 'currency'
              : selectedTab === 'currency' && (formValues?.isSticky === 'true' || formValues?.isSticky)
                ? 'wageringContribution'
                : selectedTab === 'wageringContribution' || selectedTab === 'currency' ? 'games' : 'countries'))
      }
    } else if (formValues.bonusType === 'balance') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        delete formValues?.paymentMethods
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        dispatch(
          createBonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              appliedBonusId: formValues.appliedBonusVal,
              other: checkOther(safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity }))
            },
            navigate
          })
        )
      } else {
        setSelectedTab(selectedTab === 'general'
          ? (languages?.length ? 'languages' : 'countries')
          : 'countries')
      }
    } else {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        if (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType)) {
          for (let i = 0, len = formValues?.loyaltyLevel?.length; i < len; i++) {
            delete formValues?.loyaltyLevel?.[i].startPoint
            delete formValues?.loyaltyLevel?.[i].endPoint
            formValues.loyaltyLevel[i].bonusPercentage = formValues?.loyaltyLevel?.[i].bonusPercentage / 100
          }
        }
        delete formValues?.showBonusValidity
        const timePeriod = formValues?.timePeriod
        delete formValues?.timePeriod
        formValues?.appliedBonusId && delete formValues?.appliedBonusId
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        formValues.paymentMethods = formValues?.paymentMethods ? safeStringify(formValues?.paymentMethods) : {}
        dispatch(
          createBonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other: checkOther(safeStringify(formValues?.bonusType === 'match'
                ? { countries: countryData, showBonusValidity: myShowBonusValidity }
                : { loyaltyLevel: formValues?.loyaltyLevel, timePeriod, countries: countryData, showBonusValidity: myShowBonusValidity })),
              loyaltyLevel: ''
            },
            navigate
          })
        )
      } else {
        setSelectedTab(
          selected => selected === 'general'
            ? languages.length ? 'languages' : (formValues?.bonusType !== 'winBoost' ? 'currency' : 'loyalty')
            : (selected === 'languages'
                ? (formValues?.bonusType !== 'winBoost' ? 'currency' : 'loyalty')
                : selected === 'currency'
                  ? (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType)
                      ? 'loyalty'
                      : formValues?.bonusType === 'zero_deposit'
                        ? 'wageringContribution'
                        : 'payment')
                  : selectedTab === 'loyalty'
                    ? formValues?.bonusType === 'winBoost' ? 'countries' : 'wageringContribution'
                    : selectedTab === 'wageringContribution' ? 'countries' : 'wageringContribution')
        )
      }
    }
  }

  const [preview, setPreview] = useState({
    image_preview: !isClone ? bonusDetail?.imageUrl : null,
    image_file: !isClone ? bonusDetail?.imageUrl : null
  })

  useEffect(() => {
    if (bonusDetail?.imageUrl && !isClone) {
      setPreview({
        image_preview: bonusDetail?.imageUrl,
        image_file: bonusDetail?.imageUrl
      })
    }
    if (bonusDetail?.gameIds && !bonusDetail?.other?.hub88PrepaidDetails?.length) {
      setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])

  const handleImagePreview = (e) => {
    if (e.target.files[0]) {
      const imageAsBase64 = URL.createObjectURL(e.target.files[0])
      const imageAsFiles = e.target.files[0]
      setPreview({
        image_preview: imageAsBase64,
        image_file: imageAsFiles
      })
    }
  }

  useEffect(() => {
    dispatch(getAllClientsStart({
      search: '',
      limit: 100,
      pageNo: 1,
      orderBy: 'email',
      sort: 'desc'
    }))
  }, [])

  useEffect(() => {
    let options = []
    if (portalsFilterData && selectedClient) {
      options = portalsFilterData.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
      setPortalOptions(options)
    }
  }, [portalsFilterData, selectedClient])

  useEffect(() => {
    if (balanceBonusOptions) {
      const options = balanceBonusOptions.map((opt) => { return ({ label: opt.promotionTitle?.EN, value: opt.bonusId, country: opt?.other?.countries }) })
      setAppliedBonusOptions(options)
    }
  }, [balanceBonusOptions])

  useEffect(() => {
    if (selectedClient) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  useEffect(() => {
    const options = casinoProvidersData?.rows?.map((provider) => { return { value: provider.masterCasinoProviderId, label: provider.name } })
    setProviderOptions(options)
  }, [casinoProvidersData])

  useEffect(() => {
    dispatch(getWageringTemplateStart({ isTenant: false, adminId: selectedClient }))
  }, [selectedClient])

  return {
    selectedTab,
    setSelectedTab,
    clientsFilterData,
    setSelectedClient,
    portalOptions,
    providerOptions,
    state,
    setState,
    dispatch,
    navigate,
    preview,
    handleImagePreview,
    activeTab,
    setActiveTab,
    curr,
    setCurr,
    wageringTemplateList,
    wageringTemplateDetail,
    enableSubmit,
    setEnableSubmit,
    loading,
    handelCreateBonus,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    casinoGamesData,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    gameIds,
    setGameIds,
    selectedClient,
    loyaltyLevel,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    languages,
    data,
    setData,
    set,
    loyaltyCount,
    setLoyaltyCount,
    countryData,
    setCountryData,
    prepaidsData,
    setPrepaidsData
  }
}

export default useCreateBonus
