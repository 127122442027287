export const checkLabels = (bonusType) => {
  if (['freespins', 'cashfreespins', 'balance', 'match', 'promotion'].includes(bonusType)) {
    return [
      { label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' },
      { label: 'Visible In Promotions', value: 'visibleInPromotions', message: 'If true visible in promotion else not' }
    ]
  } else {
    return [
      { label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' }
    ]
  }
}

export const daysLabels = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const convertAmountOptions = [
  { label: 'Max Bonus Claimed', value: 'maxBonusThreshold' },
  { label: 'Min Deposit', value: 'minDeposit' },
  { label: 'Max Win Amount', value: 'maxWinAmount' },
  { label: 'Zero Out Threshold', value: 'zeroOutThreshold' },
  { label: 'Min Wallet Balance', value: 'minBalance' },
  { label: 'Max Allowed Balance', value: 'minBalanceCash' },
  { label: 'Max Bet Amount', value: 'maxBetAllowed' }
]

export const convertCurrencyAmountDepositOptions = [
  { label: 'Min Deposit', value: 'minDeposit' },
  { label: 'Max Deposit', value: 'maxDeposit' },
  { label: 'Deposit Amount', value: 'depositAmount' },
  { label: 'KYC Deposit Amount', value: 'kycDepositAmount' }
]

export const convertCurrencyAmountWithdrawalOptions = [
  { label: 'Min Withdraw', value: 'minWithdraw' },
  { label: 'Max Withdraw', value: 'maxWithdraw' }
]

export const bonusType = [
  { label: 'MATCH', value: 'match', id: 0 },
  { label: 'BALANCE', value: 'balance', id: 1 },
  { label: 'BONUS FREESPINS', value: 'freespins', id: 2 },
  { label: 'CASH FREESPINS', value: 'cashfreespins', id: 3 },
  { label: 'DEPOSIT(CASHBACK)', value: 'deposit', id: 4 },
  { label: 'WAGERING(CASHBACK)', value: 'wagering', id: 5 },
  { label: 'PROMOTION', value: 'promotion', id: 6 },
  { label: 'WIN BOOST', value: 'winBoost', id: 7 }
  // { label: 'ZERO DEPOSIT', value: 'zero_deposit', id: 8 }
]

export const wageringRequirementType = [
  { label: 'BONUS', value: 'bonus', id: 1 },
  { label: 'BONUS+DEPOSIT', value: 'bonusdeposit', id: 2 }
]

export const tabLabels = [
  'general', 'currency', 'payment', 'wageringContribution', 'games', 'loyalty'
]
