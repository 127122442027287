import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, Form as BForm } from '@themesberg/react-bootstrap'
import { safeStringify } from '../../../../../utils/helpers'
import { getBonusPrepaidListStart } from '../../../../../store/redux-slices/superAdminCasinoManagement'
import { InlineLoader } from '../../../../../components/Preloader'
import { toast } from '../../../../../components/Toast'

const PrepaidsModal = ({ show, data, setShow, setData, values, prepaidsData, setPrepaidsData, gameId, userData, tenantId }) => {
  const dispatch = useDispatch()
  const { tenantDetails } = useSelector((state) => state.login)
  const { tenantDetails: SATenantDetails } = useSelector((state) => state.tenants)
  const [bonusPrepaidData, setBonusPrepaidData] = useState({})
  const { bonusPrepaidList, bonusPrepaidListLoading } = useSelector((state) => state.superAdminCasino)

  useEffect(() => {
    if (!bonusPrepaidListLoading && bonusPrepaidList) {
      const result = bonusPrepaidList
        .flat()
        .reduce((acc, { currency, ...rest }) => {
          if (!acc[currency]) {
            acc[currency] = []
          }
          acc[currency].push({ ...rest, currency })
          return acc
        }, {})
      setBonusPrepaidData(result)
    }
  }, [bonusPrepaidList])

  useEffect(() => {
    data &&
      dispatch(
        getBonusPrepaidListStart({
          currencyCode: safeStringify(values
            ? Object.keys(values.currency)
            : userData?.currencyCode
              ? [userData?.currencyCode]
              : tenantId ? tenantDetails?.tenantConfig?.allowedCurrencies : SATenantDetails?.tenantConfig?.allowedCurrencies),
          gameIdentifier: data,
          tenantId: values ? values?.tenantIds?.[0]?.value : tenantId || userData?.tenantId
        })
      )
  }, [data])

  return (
    <Modal show={show} size='xl'>
      <Modal.Header className='justify-content-center'>
        <h4>Prepaids for GameId: {data}</h4>
      </Modal.Header>
      <Formik
        initialValues={{
          bonusId: ''
        }}
        // validationSchema={giveBonusSchema}
        onSubmit={() => {
          if (prepaidsData?.filter(({ game_code }) => game_code === data)?.length !== Object.keys(bonusPrepaidData)?.length) {
            toast('Please Select Prepaids for each currency', 'error')
          } else if (prepaidsData?.filter(({ bet_value, bet_count }) => !bet_count || !bet_value)?.length) {
            toast('Please fill all bet value and freespin count fields', 'error')
          } else {
            setShow(false)
          }
        }}
      >
        {({ handleSubmit }) => (
          <Form className='align-items-center mt-3'>
            <Modal.Body>
              {bonusPrepaidListLoading
                ? <InlineLoader />
                : Object.keys(bonusPrepaidData)?.length > 0
                  ? Object.keys(bonusPrepaidData)?.map((curr) => (
                    <div
                      key={curr}
                      className='d-flex justify-content-center align-items-center mb-2'
                    >
                      <BForm.Label className='mx-2 fs-6'>{curr}</BForm.Label>
                      <BForm.Select
                        name={curr}
                    // value={prepaidsData?.filter(({ game_code, currency }) => currency === curr && game_code === gameId)?.prepaid_uuid}
                        style={{ maxWidth: '750px' }}
                        onChange={(e) => {
                          const currentPrepaidObject = bonusPrepaidData?.[curr]?.filter(({ prepaid_uuid }) => prepaid_uuid === e.target.value)?.[0]
                          const ifPrepaidExists = prepaidsData?.filter(({ currency, game_code }) => currency === currentPrepaidObject?.currency && game_code === data)?.[0] || null
                          if (ifPrepaidExists) {
                            setPrepaidsData([...prepaidsData?.filter(({ currency, game_code }) => !(currency === ifPrepaidExists?.currency && game_code === data)), { ...currentPrepaidObject, game_code: data, gameId, bet_value: currentPrepaidObject?.bet_value / 100000 }])
                          } else {
                            setPrepaidsData([...prepaidsData, { ...currentPrepaidObject, game_code: data, gameId, bet_value: currentPrepaidObject?.bet_value / 100000 }])
                          }
                        }}
                      >
                        <option selected disabled value=''>
                          ---Select Prepaids---
                        </option>
                        {bonusPrepaidData?.[curr]?.map(({ prepaid_uuid, bet_count, bet_value }) => (
                          <option key={prepaid_uuid} value={prepaid_uuid}>
                            {`(Freespin Count: ${bet_count}) (Bet Value: ${bet_value / 100000}) (Prepaid UUID: ${prepaid_uuid})`}
                          </option>
                        ))}
                      </BForm.Select>

                      <div className='d-flex justify-content-center align-items-center'>
                        <BForm.Label className='mx-2 fs-6'>Freespin Count</BForm.Label>
                        <BForm.Control
                          name='code'
                          type='number'
                          className='mx-2'
                          value={prepaidsData?.filter(({ currency, game_code }) => currency === curr && game_code === data)?.[0]?.bet_count || ''}
                          onChange={(e) => {
                            const prepaidObj = prepaidsData?.filter(({ currency, game_code }) => currency === curr && game_code === data)?.[0]
                            prepaidObj.bet_count = e.target.value ? parseInt(e.target.value) : ''
                            setPrepaidsData([...prepaidsData?.filter(({ currency, game_code }) => !(currency === curr && game_code === data)), prepaidObj])
                          }}
                        />
                      </div>

                      <div className='d-flex justify-content-center align-items-center'>
                        <BForm.Label className='mx-2 fs-6'>Bet Value</BForm.Label>
                        <BForm.Control
                          name='code'
                          type='number'
                          className='mx-2'
                          value={prepaidsData?.filter(({ currency, game_code }) => currency === curr && game_code === data)?.[0]?.bet_value || ''}
                          onChange={(e) => {
                            const prepaidObj = prepaidsData?.filter(({ currency, game_code }) => currency === curr && game_code === data)?.[0]
                            prepaidObj.bet_value = e.target.value ? parseInt(e.target.value) : ''
                            setPrepaidsData([...prepaidsData?.filter(({ currency, game_code }) => !(currency === curr && game_code === data)), prepaidObj])
                          }}
                        />
                      </div>
                    </div>
                  )
                  )
                  : <h5 className='text-danger d-flex justify-content-center'>No Data Found</h5>}
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
              <Button
                className='m-2'
                variant='outline-warning'
                onClick={() => {
                  setPrepaidsData(prepaidsData?.filter(({ game_id }) => game_id === data))
                  setShow(false)
                }}
              >
                Cancel
              </Button>
              <Button
                className='m-2'
                variant='outline-success'
                onClick={handleSubmit}
              >
                Add
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default PrepaidsModal
