import * as Yup from 'yup'

const CredentialsSchema = Yup.object().shape({
  credKey: Yup.string().required('Key Required')
  // value: Yup.string().max(100).required('Value Required'),
  // description: Yup.string().max(200).required('Description Required')
})

export const affiliateSystemSchema = (myKeys) => {
  const validationObject = {}
  if (myKeys && Object.keys(myKeys)?.length) {
    for (const file of myKeys) {
      validationObject[file] = Yup.string().required('Value Required.')
    }
  }
  return Yup.object(validationObject)
}

export default CredentialsSchema
