import { Button } from '@themesberg/react-bootstrap'
import { Form, Formik } from 'formik'
import React from 'react'
import { formatDateYMD } from '../../utils/dateFormatter'
import { toast } from '../../components/Toast'
import { freeSpinFormSchema } from './schema'
import FormFields from './FormFields'

const FreeSpinForm = ({
  bonusOptions,
  setShow,
  gameIds,
  submitData,
  selectedAggregator,
  prepaidsData
}) => {
  return (
    <>
      <Formik
        initialValues={{
          quantity: 1,
          betLevel: 1,
          validTo: formatDateYMD(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)),
          validFrom: formatDateYMD(new Date()),
          bonusId: ''
        }}
        validationSchema={freeSpinFormSchema}
        onSubmit={(formValues) => {
          selectedAggregator?.includes('hub88')
            ? submitData({ ...formValues, gameIds: Array.from(new Set(prepaidsData?.map(({ gameId }) => gameId))), hub88PrepaidDetails: prepaidsData })
            : submitData(formValues)
          setShow(false)
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
          <Form className='align-items-center mt-3'>
            <FormFields
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              bonusOptions={bonusOptions}
              setFieldValue={setFieldValue}
              isHub88={selectedAggregator?.includes('hub88')}
            />

            <div
              className='mt-4 d-flex justify-content-between align-items-center'
            >
              <Button
                variant='outline-warning'
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>

              <div>
                <Button
                  variant='outline-success'
                  onClick={() => {
                    selectedAggregator?.includes('hub88')
                      ? Array.from(new Set(prepaidsData?.map(({ gameId }) => gameId)))?.length !== gameIds?.length
                        ? toast('Please Select Prepaids', 'error')
                        : handleSubmit()
                      : !gameIds?.length
                          ? toast('Select At Least One Game', 'error')
                          : handleSubmit()
                  }}
                  className='ml-2'
                >
                  Issue
                </Button>
              </div>
            </div>

          </Form>
        )}
      </Formik>
    </>
  )
}

export default FreeSpinForm
