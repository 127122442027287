import { Modal, Table } from '@themesberg/react-bootstrap'
import React from 'react'

const PrepaidDetails = ({ show, data, hub88Details, setShow }) => {
  return (
    <Modal show={show} size='lg' onHide={() => setShow(false)}>
      <Modal.Header className='justify-content-center' closeButton>
        <h4>Prepaids for GameId: {data}</h4>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered className='text-start'>
          <thead>
            <tr className='thead-dark text-center'>
              <th>Currency</th>
              <th>Bet Count</th>
              <th>Bet Value</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {hub88Details?.map(({ currency, bet_value, bet_count }) => (
              <tr key={currency}>
                <td>{currency}</td>
                <td>{bet_count}</td>
                <td>{bet_value}</td>
              </tr>))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default PrepaidDetails
