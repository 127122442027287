import React from 'react'
import {
  Button,
  Col,
  Row,
  Spinner,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { TenantRoutes } from '../../../../routes'
import CredentialSchema from '../schemas'
import Preloader from '../../../../components/Preloader'
import useCreateCredentials from '../hooks/useCreateCredentials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { credKeyNames } from '../../../../utils/constants'

const CreateCredentials = ({
  credKey: editKey,
  value: editValue,
  description: editDescription
}) => {
  const {
    navigate,
    credKeys,
    loading,
    myKeys,
    createCredentials,
    updateCredentials
  } = useCreateCredentials(editKey)

  const createComponent = () => (
    <>
      <option value='' disabled>
        ---Select Key---
      </option>

      {myKeys === null ||
        myKeys?.length < 1
        ? (
          <option value='' disabled>
            No Credentials To Create
          </option>
          )
        : (
            credKeys &&
          myKeys.map(({ credentialKeyId, name }) => (
            <option key={credentialKeyId} value={name}>
              {credKeyNames?.[name] || name}
            </option>
          )
          )
          )}
    </>
  )

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col sm={8}>
                <h3><FontAwesomeIcon icon={faLock} />&nbsp;Credentials</h3>
              </Col>
            </Row>

            <h4>{editKey ? 'Edit' : 'Create'}</h4>

            <Formik
              initialValues={{
                credKey: editKey || '',
                value: editValue || '',
                description: editDescription || ''
              }}
              validationSchema={CredentialSchema}
              onSubmit={({ credKey, value, description }) => {
                editKey
                  ? updateCredentials({
                    key: credKey,
                    value: value.trim(),
                    description: description.trim(),
                    navigate
                  })

                  : createCredentials({
                    key: credKey,
                    value: value.trim(),
                    description: description.trim(),
                    navigate
                  })
              }}
            >
              {({ values, handleChange, handleSubmit, handleBlur }) => (
                <Form>
                  <Row>
                    <Col>
                      <BForm.Label>
                        Key <span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Select
                        name='credKey'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.credKey}
                        disabled={!!editKey}
                      >
                        {editKey
                          ? (
                            <option value={editKey} key={editKey}>
                              {credKeyNames?.[editKey] || editKey}
                            </option>
                            )
                          : (
                              createComponent()
                            )}
                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='credKey'
                        className='text-danger'
                      />
                    </Col>

                    <Col>
                      <BForm.Label>
                        Value
                      </BForm.Label>

                      <BForm.Control
                        type='text'
                        name='value'
                        placeholder='Enter Value'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.value}
                      />

                      <ErrorMessage
                        component='div'
                        name='value'
                        className='text-danger'
                      />
                    </Col>
                  </Row>

                  <Row className='mt-3'>
                    <Col>
                      <BForm.Label>
                        Description
                      </BForm.Label>

                      <BForm.Control
                        type='text'
                        name='description'
                        placeholder='Enter Description'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />

                      <ErrorMessage
                        component='div'
                        name='description'
                        className='text-danger'
                      />
                    </Col>
                  </Row>

                  <div className='mt-4 d-flex justify-content-between align-items-center'>
                    <Button
                      variant='outline-warning'
                      onClick={() => navigate(TenantRoutes.Credentials)}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant='outline-success'
                      onClick={handleSubmit}
                      className='ml-2'
                    >
                      {editKey ? 'Update' : 'Submit'}
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
          )}
    </>
  )
}

export default CreateCredentials
