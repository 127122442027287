import { useEffect, useState } from 'react'
import { toast } from '../../../../components/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getAllCasinoGamesStart, getAllExcludedCasinoGamesStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { createBannedGamesStart, getBannedGamesListDetailStart, updateBannedGamesStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getItem } from '../../../../utils/storageUtils'
import { checkIsTenant } from '../../../../utils/constants'

const useCreateBannedGames = () => {
  const [selectedProvider, setSelectedProvider] = useState('')
  const { casinoGamesData } = useSelector((state) => state.superAdminCasino)
  const { loading, getBannedGamesListDetail, removeBannedGames, listName } = useSelector((state) => state.superAdminTransactions)

  const [gameContribution, setGameContribution] = useState({})
  const [adminIds, setAdminIds] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [contributionDefaultValue, setContributionDefaultValue] = useState({})
  const [state, setState] = useState({ name: '' })
  const isInitialRender = useDidMountEffect()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const { bannedGamesListId } = useParams()
  const url = window.location.href
  const isTenant = url.includes('/tenant/')
  const isClone = url.includes('/clone/')
  const totalPages = Math.ceil(casinoGamesData?.count / limit)
  const { allProviders } = useSelector((state) => checkIsTenant() ? state.tenantCasino : state.superAdminCasino)

  useEffect(() => {
    if (isClone || bannedGamesListId) {
      dispatch(getBannedGamesListDetailStart({
        isTenant,
        bannedGamesListId,
        limit,
        pageNo: page,
        providerId: selectedProvider,
        search: search.replace(/\s/g, '')
      }))
    }
  }, [])

  useEffect(() => {
    if (!isClone && bannedGamesListId && getBannedGamesListDetail) {
      setState({ ...state, name: getBannedGamesListDetail?.bannedGamesListName || '' })
    }
  }, [getBannedGamesListDetail])

  const formSubmitHandler = (formValue) => {
    const gameContributionValue = { ...contributionDefaultValue, ...gameContribution }
    const gameData = {
      name: (isClone || bannedGamesListId) ? listName : formValue?.name || '',
      games: Object.keys(gameContributionValue),
      // adminIds
      isClone
    }
    const removedGames = removeBannedGames.map(item => item.identifier) || []
    if (!(isClone || bannedGamesListId) && Object.keys(gameContribution).length < 1) {
      toast('Select At Least One Game', 'error')
    } else if (!(isClone || bannedGamesListId) && !(formValue?.name || state?.name)) {
      toast('Please Enter Name', 'error')
    } else if (bannedGamesListId) {
      dispatch(updateBannedGamesStart({ isTenant, gameData: { ...gameData, bannedGamesListId, removedGames }, navigate }))
    } else {
      dispatch(createBannedGamesStart({ isTenant, gameData, navigate }))
    }
  }

  const multiAdminHandler = (options) => {
    const adminId = []
    options.map((item) => adminId.push(item.adminUserId))
    setAdminIds(adminId)
  }

  // const fetchDetails = (wageringTemplateId) => {
  //   dispatch(getWageringTemplateDetailsStart({
  //     isTenant: false,
  //     wageringTemplateId,
  //     limit: 15,
  //     pageNo: 1,
  //     providerId: selectedProvider
  //   }))
  // }

  // useEffect(() => {
  //   if (location?.state?.isClone) {
  //     setIsClone(true)
  //     fetchDetails(location.state.wageringTemplateId)
  //   } else {
  //     setGameContribution({})
  //   }
  // }, [location])

  // useEffect(() => {
  //   if (location?.state?.isClone && wageringTemplateDetail) {
  //     setContributionDefaultValue(wageringTemplateDetail?.gameContribution)
  //   }
  // }, [wageringTemplateDetail])

  const fetchCasinoGamesDetail = () => {
    if (bannedGamesListId) {
      dispatch(
        getAllExcludedCasinoGamesStart({
          isTenant,
          limit,
          pageNo: page,
          search: search.replace(/\s/g, ''),
          selectedProvider,
          bannedGamesListId
        })
      )
    } else {
      dispatch(
        getAllCasinoGamesStart({
          isTenant,
          limit,
          pageNo: page,
          casinoCategoryId: '',
          search: search.replace(/\s/g, ''),
          isActive: '',
          tenantId: '',
          selectedProvider,
          portalId: getItem('tenant-id') || ''
        })
      )
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          fetchCasinoGamesDetail()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    fetchCasinoGamesDetail()
  }, [selectedProvider, page, limit])

  return {
    loading,
    navigate,
    page,
    limit,
    setLimit,
    setPage,
    totalPages,
    selectedProvider,
    setSelectedProvider,
    gameContribution: gameContribution || {},
    casinoGamesData,
    setGameContribution,
    formSubmitHandler,
    multiAdminHandler,
    isClone,
    state,
    setState,
    search,
    setSearch,
    setContributionDefaultValue,
    contributionDefaultValue,
    bannedGamesListId,
    getBannedGamesListDetail,
    isTenant,
    allProviders
  }
}

export default useCreateBannedGames
