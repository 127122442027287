import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTABonusStart } from '../../../../../store/redux-slices/bonus'
import { getPaymentProviderStart } from '../../../../../store/redux-slices/payment'
import { getTAFreeSpinGamesStart } from '../../../../../store/redux-slices/tenantCasino'
import { getWageringTemplateDetailStart } from '../../../../../store/redux-slices/wageringTemplate'
import useDidMountEffect from '../../../../../utils/useDidMountEffect'

const useBonusDetail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bonusId } = useParams()
  const [selectedTab, setSelectedTab] = useState('general')
  const { loading, bonusDetail } = useSelector((state) => state.bonus)
  const { paymentProviders } = useSelector((state) => state.payment)
  const { tenantDetails } = useSelector((state) => state.login)
  const { wageringTemplateDetail } = useSelector(state => state.wageringTemplate)
  const [pageNo, setPageNo] = useState(1)
  const [limit, setLimit] = useState(15)
  const totalPages = Math.ceil(wageringTemplateDetail?.gameDetail?.count / limit)
  const isInitialRender = useDidMountEffect()
  const [search, setSearch] = useState('')
  const [wagerSearch, setWagerSearch] = useState('')
  const [selectedProvider, setSelectedProvider] = useState('')
  const [gameIds, setGameIds] = useState(bonusDetail?.gameIds || [])
  const { masterGames: casinoGamesData } = useSelector(state => state.tenantCasino)
  const [gamesPageNo, setGamesPageNo] = useState(1)
  const [gamesLimit, setGamesLimit] = useState(15)
  const [selectedLang, setSelectedLang] = useState('EN')
  const [countryData, setCountryData] = useState([])

  useEffect(() => {
    if (bonusId) {
      dispatch(getTABonusStart({ bonusId, userBonusId: '' }))
    }
  }, [bonusId])

  useEffect(() => {
    if (bonusDetail) {
      const key = Object.keys(bonusDetail?.promotionTitle)
      setSelectedLang(key?.[1] !== 'EN' ? key?.[1] : key?.[0])
      bonusDetail?.bonusType === 'match' &&
        dispatch(getPaymentProviderStart({
          isTenant: true,
          paymentType: '',
          aggregator: '',
          tenant: '',
          group: '',
          status: '',
          tenantIds: JSON.stringify([bonusDetail?.tenantId])
        }))
    }
  }, [bonusDetail])

  const getTemplateDetails = () => {
    dispatch(
      getWageringTemplateDetailStart({
        wageringTemplateId: bonusDetail?.WageringTemplate?.wageringTemplateId,
        isTenant: true,
        limit,
        pageNo,
        providerId: '',
        search: wagerSearch
      })
    )
  }

  useEffect(() => {
    if (bonusDetail?.WageringTemplate?.wageringTemplateId) {
      setPageNo(1)
      getTemplateDetails()
    }
  }, [bonusDetail?.WageringTemplate?.wageringTemplateId, limit])

  useEffect(() => {
    if (bonusDetail?.WageringTemplate?.wageringTemplateId) {
      !isInitialRender && getTemplateDetails()
    }
  }, [pageNo])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          bonusDetail && bonusDetail?.bonusId === parseInt(bonusId) && bonusDetail?.bonusType === 'freespins' &&
          getTemplateDetails()
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [wagerSearch])

  useEffect(() => {
    bonusDetail && bonusDetail?.bonusId === parseInt(bonusId) && bonusDetail?.bonusType === 'freespins' &&
    dispatch(
      getTAFreeSpinGamesStart({
        limit: gamesLimit,
        pageNo: gamesPageNo,
        search,
        providerId: selectedProvider,
        bonusId
      })
    )
  }, [gamesLimit, gamesPageNo, selectedProvider, bonusDetail])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          bonusDetail && bonusDetail?.bonusId === parseInt(bonusId) && bonusDetail?.bonusType === 'freespins' &&
          dispatch(
            getTAFreeSpinGamesStart({
              limit: gamesLimit,
              pageNo: gamesPageNo,
              search,
              providerId: selectedProvider,
              bonusId
            })
          )
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    if (bonusDetail) {
      setCountryData(bonusDetail?.other?.countries)
      !bonusDetail?.other?.hub88PrepaidDetails?.length && setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])

  return {
    selectedTab,
    setSelectedTab,
    bonusDetail,
    loading,
    bonusId,
    paymentProviders,
    navigate,
    wageringTemplateDetail,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    selectedProvider,
    setSelectedProvider,
    search,
    setSearch,
    gameIds,
    setGameIds,
    casinoGamesData,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    wagerSearch,
    setWagerSearch,
    selectedLang,
    setSelectedLang,
    languages: tenantDetails?.tenantConfig?.allowedLanguages,
    countryData,
    setCountryData
  }
}

export default useBonusDetail
